import './App.css';
import Navbar from "./Navbar";
import {useEffect, useLayoutEffect} from "react";
import Footer from "./Footer";
import ScrollIndicator from "./ScrollIndicator";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';


export default function Contacts() {
    const mapHeight=400

    useEffect(() => {
        document.title = 'Контакты';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="contacts">
                    <div className="inner-block">
                  <span>
                       <YMaps>

                                              <h2 className='attention-text header'>
                      Пятигорск
                      </h2>
                      <div className="contact-block">
                        <span className='contact-info'>
                      <p>
                          <a href="https://zdorovie-plus.com/">
Лечебно-диагностический семейный центр «Здоровье плюс»
                              </a>
                      </p>
<p>
    <a href="https://yandex.ru/maps/-/CDg7B4-q">
Проспект Кирова, 81
    </a>
</p>
<p>

    <table>
        <tr>
            <th>Прием:</th>
        </tr>
        <tr>
            <td>вторник-четверг </td>
            <td>8:00-16:00</td>
        </tr>
        <tr>
            <td>пятница</td>
            <td>операционный день</td>
        </tr>
        <tr>
            <td>суббота</td>
            <td>11:00-19:00</td>
        </tr>
    </table>

    </p>
                            <p>
                                Номера телефонов:
                      <ul>
                         <li>
<u>
<a href="tel:+89283376060">
+8 (928) 337-60-60
</a>
</u>
                         </li>
                          <li>
                      <u>
                          <a href="tel:+89283096060">
+8 (928) 309-60-60
                          </a>
                      </u>
                          </li>
                      </ul>
                                </p>
                      </span>
                                                      <div className='map'>
      <Map width={'100%'} height={mapHeight} defaultState={{ center: [44.035849, 43.061499], zoom: 15 }}>
        <Placemark geometry={[44.035298, 43.059135]} />
      </Map>
                                                      </div>
      </div>
                <hr/>
<h2 className='attention-text header'>
                      Ростов-на-Дону
</h2>
                        <div className="contact-block">
<span className='contact-info'>
                      <p>
                          <a href="https://eurodon61.ru/">
Медицинский центр «ЕвроДон»
                              </a>
                      </p>
<p>
    <a href="https://yandex.ru/maps/-/CDg7FZLS">
Ул. Социалистическая, 191
    </a>
</p>
                      <p>
Прием и операции по воскресеньям
                      </p>
    <p>
        Предварительная запись по телефону:
        <br/>
                      <u>
                          <a href="tel:+88633090506">
+8 (863) 309-05-06
                          </a>
                      </u>
        </p>
                      </span>

                            <div className='map'>
      <Map width={'100%'} height={mapHeight}  defaultState={{ center: [47.226199, 39.742266], zoom: 15 }}>
        <Placemark geometry={[47.225506, 39.739354]} />
      </Map>
                            </div>
      </div>
      <hr/>
      <div className="contact-block">
        <span className='contact-info'>
                      <p>
                          <a href="https://nashezdorovje.ru/">
Медицинская клиника «Наше здоровье»
                              </a>
                      </p>
                      <p>
                          <a href="https://yandex.ru/maps/-/CDg7B82M">
Ул. Вересаева 101/2с2
                          </a>
                      </p>
                      <p>
Прием: воскресенье-понедельник
                      </p>
            <p>
                                  Предварительная запись по телефонам:
                      <ul>
                          <li>

                      <u>
                          <a href="tel:+89939936373">
+8 (993) 993-63-73
                          </a>
                      </u>

                          </li>
                          <li>
                      <u>
                          <a href="tel:+89885470800">
+8 (988) 547-08-00
                          </a>
                      </u>
                          </li>
                      </ul>
                 </p>
                      </span>
                                      <div className='map'>
      <Map width={'100%'} height={mapHeight} defaultState={{ center: [47.238552, 39.809447], zoom: 13 }}>
        <Placemark geometry={[47.242264, 39.801772]} />
      </Map>
                                      </div>
      </div>

                           </YMaps>
                  </span>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    );
}
