import './App.css';
import Navbar from "./Navbar";
import {useEffect, useLayoutEffect, useState} from "react";
import Footer from "./Footer";
import ScrollIndicator from "./ScrollIndicator";
import Social from "./Social";
import {Accordion} from "./Accordion/Accordion";

    const faqList = [
        {
            q: "Есть ли онлайн консультация, если я из другого города?",
            a: "Есть. Консультация осуществляется через What’s up. \n" +
                "Мы с вами согласовываем дату и время. \n" +
                "Длительность приема 60 минут.  \n" +
                "Я вам даю полную информацию по вашей патологии, рекомендации и отвечаю на ваши вопросы."
        },
        {
            q: "В какие дни лучше делать УЗИ?",
            a: "Ультразвуковое исследование делается на 5-7 день менструального цикла. \n" +
                "\n" +
                "Начало цикла - это первый день менструации!\n" +
                "\n" +
                "Фолликулометрия делается 3-4 раза за цикл. Точные даты определяются на приеме гинеколога, учитывая длительность вашего цикла."
        },
        {
            q: "Нужна ли подготовка перед лазерным омоложением?",
            a: "Перед тем как прийти на лазерное омоложение влагалища или коррекцию стресс-недержания мочи нужно сдать микроскопическое исследование микрофлоры влагалища (фемофлор/флороценоз) и жидкостную цитологию. \n" +
                "\n" +
                "Перед лазерной коррекцией наружных половых органов и отбеливанием - дополнительного обследования НЕ ТРЕБУЕТСЯ."
        },
        {
            q: "Что такое половой покой?",
            a: "Это отсутствие сексуальной близости в течение опреденного срока. \n" +
                "Может назначаться после оперативных вмешательств или на фоне лечения."
        },
    ]

function MainPage() {

    const currentTheme = localStorage.getItem('current_theme')
    const [theme, setTheme] = useState(currentTheme ? currentTheme : 'light')

    useEffect(() => {
        document.title = 'Дарья Якубова | Гинеколог-онколог';

        localStorage.setItem('current_theme', theme)
    }, [theme]);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    function Priem(){
        window.open("https://zdorovie-plus.com/zapis")
        return false
    }

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar theme={theme} setTheme={setTheme}/>
            </header>
            <body>
            <main>
                <section id="introduction" style={{alignItems: "center", display: "flex", justifyContent: "center"}}>
                    <div className="inner-block intro-inner-block">
                        <img id="side-image" style={{ maxWidth: "18vw", minWidth: "15rem", overflow: "hidden"}} src={"/4J8A3078 (1).png"} unselectable="on"/>
                        <span className="intro-span">
              <h2>Якубова</h2>
              <h3>Дарья Юрьевна</h3>
              <p>КАНДИДАТ МЕДИЦИНСКИХ НАУК</p>
                   <p>Гинеколог, онколог, оперирующий хирург,<br/>врач эстетических методик в гинекологии</p>
                   <form action={Priem}>
                            <input type="submit" value="Записаться на прием" />
                            </form>
                            <Social black={false}/>
                  </span>
                    </div>
                </section>
<section id="faq">
    <div className="inner-block">
        <span>
              <h1 className='attention-text header'>Часто задаваемые вопросы</h1>
            <Accordion faqList={faqList}/>
        </span>
    </div>
</section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    );
}

export default MainPage;
