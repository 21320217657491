import "./FooterStyles.css"
import "./App.css"
import {Link} from "react-router-dom";
import Social from "./Social";

export default function Footer(){
    return(
        <div className="main-footer">
        <div className="footer-row">
            <div className="footer-col">
                <a href="/">
                <h1 className="attention-text" style={{margin: 0, fontWeight: 400}}>
                    Якубова<br/>
                </h1>
                    <h3 className="attention-text" style={{margin: 0, fontWeight: 400}}>
                    Дарья Юрьевна
                </h3>
                <p className="attention-text" style={{margin: 0, fontWeight: 400, marginBottom: "2rem"}}>
                    Гинеколог-онколог
                </p>
                </a>
            </div>
            <div className="footer-col footer-nav">
                <ul>
                    <li>
                        <Link to="/about">Обо мне</Link>
                    </li>
                    <li>
                        <Link to="/patients">Пациентам</Link>
                    </li>
                    <li>
                        <Link to="/profiles">Направления</Link>
                    </li>
                    <li>
                        <Link to="/reviews">Отзывы</Link>
                    </li>
                    <li>
                        <Link to="/blog">Блог</Link>
                    </li>
                    <li>
                        <Link to="/contacts">Контакты</Link>
                    </li>
                </ul>
            </div>
            <div className="footer-col">
                <Social black={true}/>
                    <a href="mailto:darayakubova@yandex.ru" target="_blank" rel="noreferrer">
                    darayakubova@yandex.ru
                    </a>

            </div>
        </div>
        <hr/>
            <div className="post-info">
                <p>
                    &copy;{new Date().getFullYear()} Все права сохранены
                </p>
            </div>
        </div>
    )
}