import {useRef} from "react";
import './Accordion.css'

export default function AccordionItem({faqItem, onClick, isOpen}) {
    const itemRef = useRef(null)

    return (
        <li className="accordion-item">
                <button className="accordion-header" onClick={() => onClick()}>

                    {(isOpen ? "− " : "+ ") + faqItem.q}
                </button>
                        <div className="accordion-collapse"
                        style={isOpen ? {height: itemRef.current.scrollHeight} : {height:"0"}}>
                        <div className="accordion-body" ref={itemRef}>
                            {faqItem.a}
                        </div>
                        </div>
        </li>
    )
}