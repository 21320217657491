import '../App.css';
import './Profile.css'
import Navbar from "../Navbar";
import {useEffect, useLayoutEffect} from "react";
import Footer from "../Footer";
import ScrollIndicator from "../ScrollIndicator";

export default function Profiles() {
    useEffect(() => {
        document.title = 'Направления';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="profiles">
                    <div className="inner-block">
                  <span>
                    <div className="profile-row">
                        <span className="profile-col">
<h2 className='profile-name'>Манипуляции</h2>
                            <hr/>
                            <ul>
                                <li>
                                    <p>
                                        УЗИ органов малого таза
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Кольпоскопия
                                    </p>
                                </li>
                            </ul>
                            <form action="/profiles/manipulations">
                            <input type="submit" value="Подробнее" />
                            </form>
                            </span>
                        <span  className="profile-col">
                    <h2 className='profile-name'>Малая хирургия радиоволновым методом</h2>
                            <hr/>
                            <ul>
                                <li>
                                    <p>
                                        Гистероскопия,<br/>
                                        гистерорезектоскопия,<br/>
                                        диагностическое выскабливание полости матки и цервикального канала (полипы, синехии, внутриматочная перегородка, субмукозная миома матки)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Конизация, <br/>
                                        эксцизия шейки матки (дисплазия шейки матки)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Удаление доброкачественных образований вульвы у влагалища (родинки, папилломы)
                                    </p>
                                </li>
                            </ul>
                             <form action="/profiles/surgery">
                            <input type="submit" value="Подробнее" />
                            </form>
                        </span>
                        <span className="profile-col">
                    <h2 className='profile-name'>Эстетическая гинекология</h2>
                            <hr/>
                            <ul>
                                <li>
                                    <p>
Введение объемообразующих препаратов (филлеры)
                                    </p>
                                </li>
                                <li>
                                    <p>
Нитевой лифтинг промежности и влагалища
                                    </p>
                                </li>
                                <li>
                                    <p>
Лазерное омоложение и лечение недержания мочи I и II степени
                                    </p>
                                </li>
                                 <li>
                                    <p>
Интимная пластика (лабиопластика, гименопластика и восстановление после родов, эстетическая коррекция)
                                    </p>
                                </li>
                            </ul>
                             <form action="/profiles/aestheticGynecology">
                            <input type="submit" value="Подробнее" />
                            </form>
                        </span>
                        </div>
                  </span>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    );
}
