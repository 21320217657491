import {useEffect, useLayoutEffect} from "react";
import ScrollIndicator from "../ScrollIndicator";
import Navbar from "../Navbar";
import Footer from "../Footer";


export default function Manipulations() {
    useEffect(() => {
        document.title = 'Манипуляции';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="manipulations">
                    <div className="inner-block">
                  <span>
                      <h2 className='attention-text header'>УЗИ органов малого таза</h2>
                      <p className='attention-text'>
Ультразвуковое исследование органов малого таза — оценивается состояние и функциональность мочеполовой системы.
</p>
                      <p>
Исследование выполняется двумя датчиками: трансвагинальным (введением датчика во влагалище)/
трансабдоминальным (через переднюю брюшную стенку)
                      </p>
<dl>
	<dt>
        <strong>
        УЗИ делается на 5-7 день менструального цикла;
        </strong>
        </dt>
    <dt>
В случае срочного обращения, исследование делается по требованию, но будьте готовы, что как правило необходимо прийти
        на повторное УЗИ по циклу или через месяц после первичного посещения;
        </dt>
    <dd>
	Если менструации нет — УЗИ можно делать в любой день;
        </dd>
    <dd>
	В день процедуры последний приём пищи за 3–4 часа до проведения манипуляции;
       </dd>
    <dt>
    Если запись на УЗИ заранее — отказаться накануне от продуктов вызывающих газообразование
        (хлебобулочные изделия, бобовые, капуста, газированные напитки, фрукты и овощи);
        </dt>
    <dd>
	Если требуется наблюдение за развитием и созреванием фолликулов, ультразвуковое исследование органов малого таза
        проводится трижды: на 8–10, 15–16 и 23–24 дни (зависит от вашего цикла).
    </dd>
</dl>
                      <strong>
НЕ ПРОВОЖУ УЗИ И СКРИНИНГИ БЕРЕМЕННЫМ ЖЕНЩИНАМ!
                      </strong>
<hr/>
<h2 className='attention-text header'>Кольпоскопия</h2>
<div style={{display:"flex"}}>
<p className='attention-text'>
Метод диагностики заболеваний шейки матки, влагалища и вульвы, который проводится с помощью специального прибора.
</p>
      <img id="side-image" style={{ maxWidth: "18vw", minWidth: "15rem"}} src={"/kol.jpeg"}/>
</div>


                      <p>
Кольпоскоп представляет собой микроскоп, который позволяет рассмотреть ткани под многократным увеличением и диагностировать патологические процессы:
                      </p>
                      <ol>
                          <li>
                              <p>
эрозия и эктопия шейки матки;
                              </p>
                          </li>
                          <li>
                          <p>
                              дисплазия эпителия шейки матки (изменения клеточного состава и предрак CIN III);
                          </p>
                          </li>
                          <li>
                              <p>
                                  новообразования (доброкачественные или злокачественные) шейки матки, влагалища, вульвы;
                              </p>
                          </li>
                          <li>
                              <p>
                                  полип цервикального канала, кисты;
                              </p>
                          </li>
                          <li>
                              папилломы, кондиломы в области слизистой влагалища, шейки матки, наружных половых органах.
                          </li>
                      </ol>
                      <p>
Подготовка:
                      </p>
                      <ul>
                          <li>
                              <p>
Нужно воздержаться от половых контактов за сутки до манипуляции;
                              </p>
                          </li>
                          <li>
                              <p>
Не спринцеваться;
                              </p>
                          </li>
                          <li>
                              <p>
Не использовать вагинальные свечи, крема, тампоны.
                              </p>
                          </li>
                      </ul>
<ol className="positive-list">
    <li>
        <p>
<strong>
        ПРОЦЕДУРА БЕЗБОЛЕЗНЕННА;
</strong>
        </p>
   </li>
    <li>
        <p>
В среднем занимает 20 минут;
        </p>
    </li>
    <li>
        <p>
Кольпоскопию нужно делать 1 раз в год.
        </p>
    </li>
</ol>
<p>
Беременность не является противопоказанием для выполнения кольпоскопии.
</p>
                  </span>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    )
}