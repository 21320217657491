import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import About from "./About";
import Patients from "./Patients";
import Contacts from "./Contacts";
import Profiles from "./Profiles/Profiles";
import Reviews from "./Reviews";
import MainPage from "./MainPage";
import Surgery from "./Profiles/Surgery"
import Manipulations from "./Profiles/Manipulations";
import AestheticGynecology from "./Profiles/AestheticGynecology"

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<MainPage/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/patients" element={<Patients/>}/>
              {/* <Route path="/blog" element={<Blog/>}/> */}
              <Route path="/contacts" element={<Contacts/>}/>
              <Route path="/profiles" element={<Profiles/>}/>
              <Route path="profiles/manipulations" element={<Manipulations/>}/>
              <Route path="profiles/surgery" element={<Surgery/>}/>
              <Route path="profiles/aestheticGynecology" element={<AestheticGynecology/>}/>
              <Route path="/reviews" element={<Reviews/>}/>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
