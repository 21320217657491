import {useEffect, useLayoutEffect} from "react";
import ScrollIndicator from "../ScrollIndicator";
import Navbar from "../Navbar";
import Footer from "../Footer";


export default function Manipulations() {
    useEffect(() => {
        document.title = 'Эстетическая гинекология';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="manipulations">
                    <div className="inner-block">
                        <span>
                            <h2 className='attention-text header'>Лабиопластика</h2>
                            <p className='attention-text'>
Лабиопластика - это эстетическая хирургическая коррекция малых половых губ и капюшона клитора
                                </p>
                            <p>
У некоторых девушек малые половые губы настолько растянуты, что это в прямом смысле мешает качеству жизни, именно
                            происходит трение о нижнее белье или при половой жизни, соответственно образуются раны,
                            которые долго заживают.
                                <br/>
Или же пациентки обращаются с эстетическим аспектом.
                            </p>

<p className='attention-text'>
Эстетика - это когда хочется сделать лучше чем есть.
</p>
             <p>
Данная процедура проводится когда:
             </p>
<ol>
    <li>
	Ассимметрия половых губ (одна больше-другая меньше)
    </li>
    <li>
	Растяжение тканей после естественных, что тоже вызывает не приятные ощущения и  дискомфорт
    </li>
</ol>
                            <p>
Это операция делается под анестезией.
                                <br/>
Занимает от 30-60 минут.
                            </p>
<p>
Перед операцией необходимо сдать определенный перечень анализов.
</p>
<p>
Противопоказания:
</p>
                            <ol>
               <li>
	Опухоли
               </li>
               <li>
	Воспалительные заболевания в стадии обострения
               </li>
               <li>
	Заболевания или патология крови
               </li>
               <li>
	Возраст младше 18 лет
               </li>
                            </ol>
<p>
Проконсультируйтесь со своим лечащим врачом.
</p>
                            <hr/>
<h2 className='attention-text header'>Филлеры на основе гиалуроновой кислоты</h2>
                            <p className='attention-text'>
Это введение объемобразующих препаратов на основе гиалуроновой кислоты.
</p>
                            <p>
Используются для коррекции:
                            </p>
                            <oL>
                                <li>
	Асимметрии больших половых губ
                                </li>
                                <li>
	Сужения входа во влагалище
                                </li>
                                <li>
	Увеличение точки G для лучшей чувствительности при половом акте.
                                </li>
                                <li>
	Восполнение баланса гиалуроновой кислоты во влагалище и в области наружных половых органов для лучшего увлажнения и омоложения.
                                </li>
                                <li>
	Липодистрофии половых губ - когда отмечается дряблая кожа и наличие мелких морщинок наружных половых органов
                                </li>
                                <li>
	Зияния половой щели (когда вход во влагалище полностью не закрывается половыми губами) и вследствие чего можно услышать «хлюпающие» звуки во время полового акта.
                                </li>
                            </oL>

                            <p>
Процедура выполняется под местной анестезией!
                                <br/>
Зона воздействия и количество препарата обговаривается с врачом гинекологом.
                            </p>
<p>
Эффект длится от 6-12 месяцев.
</p>
                              </span>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    )
}