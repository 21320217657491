import './SocialStyles.css'
import React from 'react';
import {SiInstagram, SiTelegram, SiVk, SiWhatsapp} from "react-icons/si";

export default function Social () {

    return (
            <div className="social" style={{justifyContent: "center"}}>
                <a href="https://api.whatsapp.com/send/?phone=79289888915"  target="_blank" rel="noreferrer">
                    <SiWhatsapp style={{color: '#128c7e'}} className='social-icon' />
                </a>
                <a href="https://www.instagram.com/dr_yakubova_/"  target="_blank" rel="noreferrer">
                <svg width="0em" height="0em">
                <radialGradient id="rg" r="150%" cx="30%" cy="107%">
    <stop stopColor="#fdf497" offset="0" />
    <stop stopColor="#fdf497" offset="0.05" />
    <stop stopColor="#fd5949" offset="0.45" />
    <stop stopColor="#d6249f" offset="0.6" />
    <stop stopColor="#285AEB" offset="0.9" />
  </radialGradient>
</svg>
                    
                    <SiInstagram style={{ fill: "url(#rg)" }} className='social-icon'/>
                </a>
                <a href="https://vk.com/dr_yakubova" target="_blank" rel="noreferrer">
                    <SiVk style={{color: '#447bba'}} className='social-icon' />
                </a>
                <a href="https://t.me/darayva" target="_blank" rel="noreferrer">
                    <SiTelegram style={{color: '#2481cc'}}  className='social-icon' />
                </a>
            </div>
    )
}