import "./NavStyles.css"
import "./App.css"
import {Link} from 'react-router-dom';
import {FaBars, FaTimes} from "react-icons/fa";
import {useRef} from "react";

export default function Navbar(props){
    const navRef = useRef()

    const showNavbar = () => {
        navRef.current.classList.toggle('resp_nav')
    }

const toggleMode = () => {
    props.setTheme(props.theme === 'light' ? 'dark' : 'light')
}

    return(
        <nav>
            <div className="logo">
            <h3>LOGO</h3>
            <div>
                <Link to="/">
                <p style={{fontSize: '1.5rem',marginBottom:0,marginTop:'1rem'}}>Dr.Yakubova</p>
                </Link>
            </div>
            </div>

            <div>
                <ul id="navbar"  ref={navRef}>
                    <li className="li-hide">
                        <Link to="/" className="main-page-link">На главную</Link>
                    </li>
                    <li>
                        <Link to="/about">Обо мне</Link>
                    </li>
                    <li>
                        <Link to="/patients">Пациентам</Link>
                    </li>
                    <li>
                        <Link to="/profiles">Направления</Link>
                    </li>
                    <li>
                        <Link to="/reviews">Отзывы</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to="/blog">Блог</Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to="/contacts">Контакты</Link>
                    </li>
                    <li className="li-hide">
                        <button className='nav-button nav-close-button' onClick={showNavbar}>
                            <FaTimes />
                        </button>
                    </li>
                </ul>
                <button className='nav-button nav-open-button' onClick={showNavbar}>
                    <FaBars />
                </button>
                {/*<p onClick={() => {toggleMode()}}>*/}
                {/*    Переключить*/}
                {/*</p>*/}
            </div>

        </nav>
    )
}