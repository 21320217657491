import './App.css';
import Navbar from "./Navbar";
import {useEffect, useLayoutEffect} from "react";
import Footer from "./Footer";
import ScrollIndicator from "./ScrollIndicator";

export default function Patients() {
    useEffect(() => {
        document.title = 'Пациентам';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="for-patients">
                    <div className="inner-block">
                  <span>
                    <hr/>
                      <p className='attention-text'>
                      Консультирую пациенток с гинекологической или онкологической патологией.
                          При необходимости на приемах выполняю УЗИ органов малого таза,
                          беру необходимые анализы и подробно консультирую по всем интересующим вопросам.<br/>
Не работаю с ведением беременности и вспомогательными репродуктивными технологиями.
                      </p>
                      <hr/>
<h2 className='attention-text header'>
Подготовка к консультации
</h2>
                      <ol className='positive-list'>
                          <li>
                              <p>
                                  Сформулировать жалобы;
                              </p>
                          </li>
                          <li>
                          <p>
Знать, когда была последняя менструация, длительность и какой у вас цикл;
                          </p>
                          </li>
                          <li>
                          <p>
При себе иметь документы предыдущих анализов и исследований для оценки анамнеза вашей патологии;
                          </p>
                          </li>
                          <li>
                          <p>
Сходить в туалет перед приёмом (исключение пациентки с недержанием мочи: в этом случае наоборот, на приём нужно прийти с полным мочевым пузырём);
                          </p>
                          </li>
                          <li>
                          <p>
Воздержаться от половых контактов за сутки до приема гинеколога;
                          </p>
                          </li>
                          </ol>
                          <ol className='negative-list'>
                          <li>
                          <p>
<strong>НЕ НУЖНО</strong> спринцеваться, вводить тампоны или вагинальные свечи в течение двух суток;
                          </p>
                          </li>
                          <li>
                          <p>
<strong>НЕ НУЖНО</strong> использовать гигиенический душ во влагалище.
                          </p>
                          </li>
                      </ol>
                      <hr/>
<h2 className='attention-text header'>
Подготовка к операции
</h2>
                      <p className='attention-text'>
После консультации и дальнейших рекомендаций вам предстоит сдать определенный список обязательных клинических анализов и исследований для проведения операции.
                      </p>
                      <h4>
 Анализы перед операцией
                     </h4>
                      <ul>
                      <li>
<p>
                          Общий анализ крови (14 дней);
</p>
                      </li>
                      <li>
                          <p>
Коагулограмма (кровь на свёртываемость) (14 дней);
                          </p>
                      </li>
                      <li>
                          <p>
Кровь на сифилис, ВИЧ, гепатиты В и С (3 месяца);
                          </p>
                      </li>
                      <li>
                          <p>
Группа крови и резус-фактор (бессрочно);
                          </p>
                      </li>
                      <li>
                          <p>
Биохимия крови: АСТ, АЛТ, общий билирубин, общий белок, глюкоза, креатинин, мочевина, натрий, калий (14 дней);
                          </p>
                      </li>
                      <li>
                          <p>
Общий анализ мочи (14 дней);
                          </p>
                      </li>
                      <li>
                          <p>
ЭКГ (кардиограмма) (1 месяц);
                          </p>
                      </li>
                      <li>
                          <p>
Рентген грудной клетки (3 месяца);
                          </p>
                      </li>
                      <li>
                          <p>
УЗИ вен нижних конечностей (3 месяца);
                          </p>
                      </li>
                      <li>
                          <p>
Мазок на флору — лучше если это будет фемофлор или флороценоз расширенный (14 дней);
                          </p>
                      </li>
                      <li>
                          <p>
PAP-тест с шейки матки или жидкостная цитология (12 месяцев);
                          </p>
                      </li>
                      <li>
                          <p>
Консультация терапевта (при серьезной сопутствующей патологии — консультация смежных специалистов);
                          </p>
                      </li>
</ul>
<strong>
    <i>
        !!! Могут понадобиться дополнительные исследования в зависимости от наличия хронических заболеваний или предъявляемых жалоб.
</i>
</strong>
                      <p>
После прохождения анализов вы высылаете окончательные результаты на почту/личный мессенджер. Далее мы вместе с
                          врачом-анестезиологом решаем вопрос, нет ли противопоказаний для дальнейшего оперативного вмешательства.
                      </p>
<p>
    Перед операцией (лапаротомным, лапароскопическим, влагалищным доступами) следует <u>подготовить кишечник</u>.
</p>
                      <p>
Можно использовать любой препарат для проведения колоноскопии (Мовипреп/Эзиклен/Фортранс).
                      </p>
<p>
    В обязательном порядке <u>самостоятельно удалить волосы в интимной зоне</u>.
</p>
<hr/>
    <h2 className='attention-text header'>
Приём пищи и воды
        </h2>
<p>
    Если операция назначена на утро, то в клинику нужно приехать <strong>НАТОЩАК</strong> (кто принимает в обязательном порядке
    сердечные препараты или любые другие лекарства на постоянной основе, то нужно взять их с собой).
    </p>
                      <p>
    Если операция назначена после обеда, то завтракать можно за 6 часов до операции, пить воду за 2 часа до операции.
</p>
                  </span>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    );
}
