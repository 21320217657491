import {useEffect, useLayoutEffect} from "react";
import ScrollIndicator from "../ScrollIndicator";
import Navbar from "../Navbar";
import Footer from "../Footer";


export default function Manipulations() {
    useEffect(() => {
        document.title = 'Манипуляции';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="manipulations">
                    <div className="inner-block">
                        <h2 className='attention-text header'>
                        Гистероскопия\Гистерорезектоскопия
                        </h2>
                        <div style={{display: "flex"}}>
                            <span>
                        <p className='attention-text'>
                        Это эндоскопический метод диагностики полости матки, перешейка, вплоть до наружного зева шейки матки.
                        </p>
                        <p>
                        Суть метода: под в/в анестезией в полость матки водится оптическая система – эндоскоп.
                            Посредством чего мы проводим диагностику полости матки и убираем патологические образования
                            находящийся внутри.
                        </p>
                            </span>
                            <img id="side-image" style={{ maxWidth: "18vw", minWidth: "15rem"}} src={"/gistero.jpeg"}/>
                        </div>
                        <p className='attention-text'>
                        В чем разница между Гистероскопией и Гистерорезектоскопией?
                        </p>
                        <p>
                        Гистероскопия (офисная гистероскопия\диагностическая гистероскопия) предназначена для осмотра
                            полости матки. Она может выполняться и без анестезиологического пособия и занимает до
                            30 минут. Т.е. мы входим в полость для осмотра, без забора материала (кусочка ткани или
                            патологического образования).
                        </p>
<p>
                        Гистерорезектоскопия – это когда помимо вхождения в полость матки нам следует взять биопсию
    (кусочек ткани), удалить образование, убрать гиперплазию эндометрия (утолщение внутреннего слоя матки) и отправить
    на гистологическое исследование с целью постановки окончательного диагноза или малоинвазивного метода лечения.
                        Данная манипуляция проходит под анестезией, соответственно может занимает от 30 до 60 минут.
    <br/>
                        Так как проводится наркоз следует предварительно сдать определенный перечень анализов
    (следует проконсультироваться с лечащим врачом).
</p>
                        <p className='attention-text'>
                        Показания:
                        </p>
                        <ol>
                            <li>
                        Миома матки (узлы растущие преимущественно в полость матки)
                            </li>
                            <li>
                        Гиперплазия эндометрия
                            </li>
                            <li>
                        Межменструальные кровянистые выделения или кровотечения
                            </li>
                            <li>
                        Полипы в полости матки
                            </li>
                            <li>
                        Удаление внутриматочных сращений (синехии)
                            </li>
                        </ol>
                        <hr/>
                        <h2 className='attention-text header'>
                        Радиоволновая конизация\эксцизия шейки матки
                            </h2>
                        <p className='attention-text'>
                        Это диагностическая лечебная процедура, в ходе которой удаляется пораженная часть шейки матки
                            путем радиоволнового электрического инструмента.
</p>
                        <p>
                        Манипуляция делается под местной или внутривенной анестезией (для сдачи перечня необходимых
                            анализов необходимо проконсультироваться с лечащим врачом).
                            <br/>
                        Занимает процедура от 20 до 60 минут
                        </p>

                        <p className='attention-text'>
                        Показания:
                        </p>
                        <ol>
                            <li>
                        Дисплазия эпителия шейки матки (CIN II\III или HSIL)
                            </li>
                            <li>
                        Предрак шейки матки (карцинома in situ) или CIN III
                            </li>
                        </ol>
<p>
                        После манипуляции исключаются на 1.5-2 месяца половые контакты, и даются индивидуальные рекомендации. Через 1 месяц обязательна явка к врачу гинекологу (хирургу) для оценки динамики заживления шейки матки.
</p>
<p>
                        <strong>Удаление папиллом осуществляется радиоволновым методом</strong>, обычно в день обращения.
<br/>
                        По согласованию с пациентом и местом локализации папилломы\кондиломы решается вопрос о
    выполнении местной инъекционной анестезии.
                        Все удаленные образования отправляются на гистологическое заключение.
</p>
                        <hr/>
                        <h2 className='attention-text header'>
                        Лазерное омоложение и лечение недержания мочи
                            <br/>
                        на аппарате BIOXEL (фракционный СО2 лазер)
                        </h2>
                        <p className='attention-text'>
                        За счет проникновения лазерной волны в ткани происходит "выпаривание верхнего слоя эпидермиса"
                            и вследствие чего начинают образовываться новые волокна коллагена и эластина.
                        </p>
                        <p>
                        Принцип воздействия апппарата - энергия лазерного излучения преобразует тепло и в ответ на
                            нагревание происходит с большей силой выработка белков теплового шока, что в свою
                            очередь запускает:
                        </p>
                        <ul>
                            <li>
                        рост фибробластов и стимулирует коллаген
                            </li>
                            <li>
                        стимулирует рост новых кровеносных сосудов
                            </li>
                            <li>
                        образование "новой" ткани, с лучшей кровеносной системой и более упругим качеством кожи и слизистой
                            </li>
                        </ul>
                        <p className='attention-text'>
                        Для кого и для чего манипуляция?
                            </p>
                        <p>
                        Возрастных ограничений у процедуры нет!
                            <br/>
                        Особенно хорошо данная манипуляция подходит женщинам после естестественных родов и женщинам с
                            нарушениями мочеиспускания (недержание или подтекание мочи).
                        </p>

                        <p className='attention-text'>
                        Мы получаем:
                        </p>
                        <ul>
                            <li>
                        эффект лифтинга стенок влагалища и половых губ. учитывая повышение их эластичности;
                            </li>
                            <li>
                        устранения признаков "хлюпающего" влагалища;
                            </li>
                            <li>
                        увеличение влагалищного секрета (при уменьшении или отсутствие естественной женской смазки);
                            </li>
                            <li>
                        противовоспалительное действие в области влагалища и шейки матки;
                            </li>
                            <li>
                        уменьшение пигментации наружных половых органов и паховых складок;
                            </li>
                            <li>
                        воздействие на рубцовую ткань после кесарево сечения, эпизиотомии или других оперативных вмешательств;
                            </li>
                            <li>
                        устранение постоянных трещин на половых губах и в области задней спайки (между входом во влагалище и анусом).
                            </li>
                            <li>
                        коррекция и лечение недержания и подтекание мочи;
                            </li>
                            <li>
                        профилактика опущения стенок влагалища 1-2 ст.
                            </li>
                            <li>
                                улучшение качества сексуальной жизни;
                            </li>
                        </ul>

                        <p className='attention-text'>
                        Подготовка:
                        </p>
                        <p>
                        сдать мазок на флору и цитологическое исследование с шейки матки на атипичные клетки
                        (жидкостная цитология) с целью устранения воспалительной патологии и дисплазии эпителия
                        шейки матки высокого риска.
                        </p>

                        <p className='attention-text'>
                        Противопоказания:
                        </p>
                        <ol>
                            <li>
                        Возраст до 18 лет;
                            </li>
                            <li>
                        Онкология;
                            </li>
                            <li>
                        Сахарный диабет 1 типа;
                            </li>
                            <li>
                        Беременность и лактация;
                            </li>
                            <li>
                        Острые воспалительные процессы в зоне коррекции;
                            </li>
                        </ol>
<p>
                        Процедура занимает до 60 минут, при необходимости используется местная анестезия.
    <br/>
                        Перед манипуляцией необходимо проконсультироваться с лечащим врачом.
</p>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    )
}