import './App.css';
import Navbar from "./Navbar";
import {useEffect, useLayoutEffect} from "react";
import Footer from "./Footer";
import ScrollIndicator from "./ScrollIndicator";
import SimpleImageSlider from "react-simple-image-slider";
import useResizeObserver from "use-resize-observer";


const images = [
    { url: "study1.jpg" },
    { url: "study2.jpg" },
    { url: "study3.jpg" },
    { url: "study4.jpg" },
    { url: "study5.jpg" },
    { url: "study6.jpg" },
    { url: "study7.jpg" },
    { url: "study8.jpg" },
    { url: "study9.jpg" },
    { url: "study10.jpg" },
    { url: "study11.jpg" },
];

export default function About() {
    const { ref, width = 1, height = 1 } = useResizeObserver();

    useEffect(() => {
        document.title = 'Обо мне';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });


    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="about">
                    <div className="inner-block" style={{display: "flex"}}>
                           <img id="side-image" style={{ maxWidth: "30rem", minWidth: "10rem", overflow: "hidden"}} src={"/4J8A2909.png"} unselectable="on" alt="side"/>
                  <span className="intro-span" style={{paddingLeft: "5vw"}}>
                      <div style={{alignItems: "flex-end", display: "flex"}}>
                          <span style={{marginBottom: "auto",marginTop: "auto"}}>
                      <p className='attention-text'>
                          я врач гинеколог, онколог, кандидат медицинских наук и врач эстетических методик в гинекологии.
                      </p>
                      <p>
А не официальная моя сторона — хочу показать женщинам как быть здоровой физически и ментально
                          (т. е. дружить со своей сексуальностью, головой и телом).
                      </p>
<p>
Я тот врач, у которой проделан большой путь со своими взлетами и падениями. С огромным чувством страха и непониманием
    действительности, с повзрослевшим  сознанием и наконец четкой позицией любви к своему делу.
</p>
<p>
Все банально: 6 лет института, потом интернатура. И тут действительно случилась "любовь" — большая хирургия.<br/>
Затем онкология закрутила меня на 6 лет, написала диссертацию и так вовлеклась, что теперь не могу остановиться.
В 2023 году решила плотно заняться своим ментальным здоровьем — пошла в психологию, женские круги,
    медитации и начала углубляться в эстетическую коррекцию и лечение гинекологических проблем.
    Начала изучать, как психосоматика влияет на женское здоровье, занялась эстетической коррекцией и лечением
    гинекологических проблем.<br/>
И поняла — как менять сознание, тело и здоровье женщины на обычном приеме врача-гинеколога.<br/>
</p>
                              </span>
                          </div>
                      </span>
                    </div>
                </section>
                <section id="education">
                    <div className="inner-block">
                  <span>
               <h1 className='attention-text header'>Образование</h1>
                                            <dl className="year-list">
                                                <dt>2023</dt>
                                                <dd>
                      <p>
                      Прошла повышение квалификации на базе ФГБУ «НМИЦ АГП им. В.И. Кулакова» МЗ РФ по
                          специализации — эстетическая гинекология;
                      </p>
                  </dd>
                  <dd>
                      <p>
                      Прошла повышение квалификации на базе ГБУЗ Краевая клиническая больница № 1 им. профессора С. В.
                          Очаповского г. Краснодар — внутриматочная диагностика
                          и хирургия заболеваний репродуктивного возраста;
                      </p>
                  </dd>
                                                <dt>2021</dt>
                                                <dd>
                      <p>
                      Прошла повышение квалификации на базе Автономной некоммерческой организации дополнительного
                          профессионального образования «Центральный многопрофильный институт» г. Москва по программе
                          Пороки развития и возрастные изменения полочных желёз. Сертификат выдан 27.12.2021;
                      </p>
                  </dd>
                  <dd>
                      <p>
                      Окончила аспирантуру на базе «Национального медицинского исследовательского центра онкологии»
                          г. Ростов-на-Дону по специальности — онкология, лучевая терапия;
                      </p>
                  </dd>
                  <dd>
                      <p>
                      Тема диссертации: «Некоторые регуляторно-метаболические и молекулярно-генетические аспекты
                          резистентности распространенного рака яичников к платиносожержащим режимам полихимиотерапии»;
                      </p>
                  </dd>
                                                <dt>2020</dt>
                                                <dd>
                      <p>
                      Прошла повышение квалификации на базе АНО ДПО «Центральный многопрофильный институт» г. Москва
                          по специальности — акушерство и гинекология. Сертификат выдан 30.11.2020;
                      </p>
                  </dd>
                  <dd>
                      <p>
                      Прошла повышение квалификации на базе ООО «Учебный центр «Академия профессионального развития»
                          г. Москва по специальности — онкология. Сертификат выдан 25.12.2020;
                      </p>
                  </dd>
                  <dd>
                      <p>
                      Прошла повышение квалификации на базе Автономной некоммерческой организации дополнительного
                          профессионального образования «Центральный многопрофильный институт» г. Москва по программе
                          Ультразвуковая диагностика в акушерстве и гинекологии. Сертификат выдан 26.12.2020;
                      </p>
                  </dd>
                                                <dt>2018</dt>
                                                <dd>
                      <p>
                      Окончила ординатуру на базе ГБОУ ВО «Ростовский Государственный Медицинский Университет»
                          МЗ РФ по специальности — онкология. Сертификат выдан 03.09.2018;
                      </p>
                  </dd>
                                                <dt>2017</dt>
                                                <dd>
                      <p>
                      Прошла повышение квалификации на базе Федерального Государственного Бюджетного Учреждения
                          «Ростовский научно-исследовательский онкологический институт» Минздрава России по дисциплине
                          «Кольпоскопическая диагностика заболеваний шейки матки». Сертификат выдан 18.07.2017;
                      </p>
                  </dd>
                  <dt>2016</dt>
                      <dd>
                      <p>
                      Окончила интернатуру на базе ГБОУ ВО «Ростовский Государственный Медицинский Университет» МЗ РФ
                          по специальности — акушерство и гинекология. Сертификат выдан 31.08.2016;
                      </p>
                          </dd>
 <dt>2015</dt>
                                                <dd>
                      <p>
     Окончила ГБОУ ВО «Ростовский Государственный Медицинский Университет» МЗ РФ по специальности — лечебное дело.
                      </p>
                                                </dd>
                      </dl>
                      <div ref={ref} className="education-slider">
<SimpleImageSlider  width={width}
                    height={height} images={images} showNavs={true} showBullets={true}/>
                          </div>
                      </span>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    );
}
