import {useEffect, useState} from "react";
import './ScrollStyle.css'

export default function ScrollIndicator() {
    const [scrollTop,setScrollTop]=useState(0)

    const onScroll = () => {
        const windowScroll = document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

        const scrolled = windowScroll / height * 100

        setScrollTop(scrolled)
    }

    useEffect(()=>{
        window.addEventListener("scroll",onScroll)

        return () => window.removeEventListener("scroll",onScroll)
    },[])

    return(
    <div className="progressWrapper">
<div className="progressFill" style={{width: `${scrollTop}%`}}>
</div>
    </div>
    )
}