import './App.css';
import Navbar from "./Navbar";
import {useEffect, useLayoutEffect} from "react";
import Footer from "./Footer";
import ScrollIndicator from "./ScrollIndicator";
import SimpleImageSlider from "react-simple-image-slider";
import useResizeObserver from "use-resize-observer";

const images = [
    { url: "review1.jpg" },
    { url: "review2.jpg" },
    { url: "review3.jpg" },
    { url: "review4.jpg" },
    { url: "review5.jpg" },
    { url: "review6.jpg" },
    { url: "review7.jpg" },
    { url: "review8.jpg" },
    { url: "review9.jpg" },
    { url: "review10.jpg" },
    { url: "review11.jpg" },
    { url: "review12.jpg" },
]

export default function Reviews() {
    const { ref, width = 1, height = 1 } = useResizeObserver();

    useEffect(() => {
        document.title = 'Отзывы';
    }, []);

    useLayoutEffect(() => {
        window.scroll(0, 0)
    });

    return (
        <>
            <header className="App-header">
                <ScrollIndicator/>
                <Navbar/>

            </header>
            <body>
            <main>
                <section id="reviews">
                    <div className="inner-block" >
                  <span >
                      <div ref={ref} className="education-slider comment-slider">
<SimpleImageSlider width={width} height={height} images={images} showNavs={true} showBullets={true} autoPlay={true} autoPlayDelay={6}/>
                      </div>
                      <p>
                          Посмотреть остальные отзывы можно <a href="https://prodoctorov.ru/pyatigorsk/vrach/1079761-yakubova/#otzivi">здесь</a>.
                      </p>

                      <form action="https://prodoctorov.ru/new/rate/doctor/891314/" >
                            <input style={{ borderColor: "black", fontWeight:600}} type="submit" value="Оставить отзыв!" />
                            </form>
                  </span>
                    </div>
                </section>
            </main>
            </body>
            <footer>
                <Footer/>
            </footer>
        </>
    );
}
